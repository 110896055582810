<template>
    <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Enviar Comando</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Nombre"
                  disabled
                  v-model="deviceNew.name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Imei"
                  hint="Identificador del Dispositivo"
                  v-model="deviceNew.uniqueid"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Id"
                  v-model="deviceNew.id"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
               <v-select 
                    :items="types" 
                    item-text="name"
                    item-value="id"
                    label="Types*" 
                    v-model="deviceNew.devicetypeid"
                    disabled
                    ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
               <v-select 
                    :items="commandListFiltered" 
                    item-text="name"
                    item-value="id"
                    label="Select Command"
                    v-model="selectedCommand"
                    :hint="selectedCommand.description"
                    @change="commChanged"
                    return-object 
                    ></v-select>
              </v-col>


              <v-col
                cols="12"
                v-if="selectedCommand.id == 2"
              >
                <v-text-field
                  label="* Valor de Tiempo "
                  v-model="valCommand"
                  type="numeric"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
          <small>* Indicador de Requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveCommand"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props:{
        deviceNew:
        {
            type: Object,
            default: ()=>{}
        },
        commandList:
        {
            type: Array,
            default: ()=>[]
        },
        dialog:
        {
            type: Boolean,
            default: () => false
        },
        types: {
          type:Array
          ,default: () =>  []
        }
    },
    data:()=>({
        selectedCommand:{}
        ,valCommand:null
    }),
    computed:{
      commandListFiltered(){
        //var devtype = this.deviceNew.devicetypeid == 1 || this.deviceNew.devicetypeid == 1?
        return this.commandList.filter(c => c.devicetypeid == this.deviceNew.devicetypeid);
      },
    },
    methods:{
      ...mapActions(['addCommand','showSnackbar']),
      
        close(){
            this.$emit('close');
        },
        saveCommand(){
            console.log(this.deviceNew);

            //TODO: verify each type for correspondign valid data
            var param = {
              devid: this.deviceNew.id,
              commid: this.selectedCommand.id,
              extra: this.valCommand
            };
            this.addCommand(param).then((res) => {
              console.log(res);
              
                this.showSnackbar({
                  showing:true,
                  message: `Commando Creado`,
                  color:'info'
                });
              this.close();
            })
            .catch((err)=>{
                console.log(err);
            });

        },
        commChanged(item){
          console.log(item);
        }
    }
}
</script>

<style>

</style>